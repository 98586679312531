import { useState } from "react"
import { AboutComponent } from "../../components/About/AboutComponent"
import { ContactComponent } from "../../components/Contact/ContactComponent"
import { GalleryComponent } from "../../components/Gallery/GalleryComponent"
import { homePhotos } from "../../components/Gallery/MainPhoto"
import { NewsComponent } from "../../components/News/NewsComponent"
import { PhotoComponent } from "../../components/PhotoComponent/PhotoComponent"
import { PopUp } from "../PopUpPage/PopUp"
import "./HomePage.scss"

export const HomePage = () => {
	const [isPopUpVisible, setIsPopUpVisible] = useState(false)

	const closePopUp = () => {
		setIsPopUpVisible(false)
	}

	return (
		<div>
			<PhotoComponent />
			<AboutComponent />
			<NewsComponent />
			<GalleryComponent images={homePhotos} />
			<ContactComponent />

			Wyświetlenie pop-upu, jeśli isPopupVisible jest true
			{isPopUpVisible && (
				<PopUp
					message='Decyzją organu prowadzącego - Jacka Prysaka, w dniu 16.09.2024 r. zajęcia lekcyjne w naszej szkole zostają ODWOŁANE. Dla uczniów potrzebujących opieki w godzinach 7.00 - 14.30 będą zorganizowane zajęcia świetlicowe.'
					closePopup={closePopUp}
				/>
			)}
		</div>
	)
}
